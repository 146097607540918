@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
/* ::-webkit-scrollbar-thumb {
  background: #ff0000;
} */

.notification-menu .MuiList-root {
  padding-top: 0 !important; /* Override the padding-top */
  padding-bottom: 0 !important; /* Override the padding-top */
}

.border_display {
  border: none;
  border-radius: 0%;
}

.ant-card-body {
  padding: 0;
  margin: 0;
}
